import { EVENT_MODE_NORMAL, EVENT_MODE_PUNCHUP, EVENT_MODE_REGISTRATION } from 'utils/constants';

import { DateTime } from 'luxon';
import { EventModeProp } from 'features/CreateEvent/components/CreateEventForm/types';
import { EventSchedule } from 'features/common/components/EventSchedule';
import { FlexContainer } from 'features/common/components/FlexContainer';
import { Form } from 'features/common/components/Form';
import { FormContainer } from './KeyDetails.styles';
import { ImgWithHover } from 'features/common/components/ImageInputs';
import { InputLabel } from 'features/common/components/Form/InputLabel';
import { PunchupDateTimePicker } from 'features/common/components/Form/PunchupDateTimePicker/PunchupDateTimePicker';
import { allowsOnlyNumbers } from 'utils/numberInput';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const KeyDetails = ({ eventMode }: EventModeProp) => {
	const { t } = useTranslation();
	const { watch, setValue } = useFormContext();

	const address = watch('location');
	const startDate = watch('dates[0].start_date');
	const endDate = watch('dates[0].end_date');
	const doorTime = watch('door_time');

	const url = new URL(window.location.href);
	const searchParams = new URLSearchParams(url.search);
	const punchupAddress = searchParams.get('address');
	const punchupVenue = searchParams.get('venue');
	const postalCode = searchParams.get('postal_code');

	useEffect(() => {
		if (postalCode) {
			setValue('postal_code', postalCode);
		}
	}, [postalCode]);

	useEffect(() => {
		if (!doorTime && startDate) {
			setValue('door_time', new Date(startDate.getTime() - 1 * 60 * 60 * 1000));
		}
		if (endDate && startDate && endDate < startDate) {
			setValue('dates[0].end_date', startDate);
		}
	}, [startDate]);

	if (eventMode === EVENT_MODE_PUNCHUP) {
		return (
			<FormContainer>
				<Form.PunchupInput id="name" label={t('Event name')} name="name" placeholder="Add your event name" />

				<Form.PunchupTextArea
					id="description"
					name="description"
					label={t('Event Description')}
					placeholder="Add your event description"
					maxLength={160}
					required={false}
				/>

				<PunchupDateTimePicker
					id="startDate"
					name="dates[0].start_date"
					label={t('Start Date')}
					required
					minDate={new Date()}
				/>
				<PunchupDateTimePicker
					id="endDate"
					name="dates[0].end_date"
					label={t('End Date')}
					required
					minDate={startDate}
				/>
				<PunchupDateTimePicker
					id="doorTime"
					name="door_time"
					label={t('Open Doors')}
					required
					maxDate={startDate}
					minTime={new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 0, 0, 0)}
					maxTime={
						DateTime.fromJSDate(startDate).hasSame(DateTime.fromJSDate(doorTime), 'day')
							? DateTime.fromJSDate(startDate).toJSDate()
							: new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 23, 59, 59)
					}
				/>

				<Form.PunchupInput
					id="venue"
					label={t('Venue')}
					name="venue"
					placeholder="Venue name"
					disabled
					style={{ backgroundColor: '#f5f5f5 !important' }}
					defaultValue={punchupVenue || ''}
				/>
				<Form.PunchupInput
					id="location"
					label={t('Location')}
					name="location"
					placeholder="Location"
					disabled
					style={{ backgroundColor: '#f5f5f5 !important' }}
					defaultValue={punchupAddress || ''}
				/>

				<Form.PunchupTimezone />
			</FormContainer>
		);
	}

	return (
		<>
			<Form.Input
				id="name"
				label={t('createEvent-eventSummary-name')}
				name="name"
				placeholder={t('createEvent-name-placeholder')}
				autoComplete="off"
			/>
			<Form.InputText
				id="description"
				name="description"
				label={t('createEvent-eventSummary-description')}
				placeholder={t('createEvent-description-placeholder')}
			/>
			<Form.AddressAutocomplete
				id="location"
				name="location"
				label={t('createEvent-eventSummary-location')}
				value={address}
			/>
			{eventMode === EVENT_MODE_NORMAL && (
				<Form.Input
					autoComplete="off"
					id="postal_code"
					label={t('createEvent-zip-code')}
					name="postal_code"
					placeholder={t('createEvent-zip-code-placeholder')}
					info={t('only-usa')}
				/>
			)}
			{eventMode === EVENT_MODE_REGISTRATION && (
				<Form.Input
					id="quantity"
					label={t('createEvent-registrations')}
					name="quantity"
					placeholder="100"
					type="number"
					onKeyDown={allowsOnlyNumbers}
				/>
			)}
			{/* {eventMode === EVENT_MODE_REGISTRATION && (
				<Form.InputToggle htmlFor="approval_required" name="approval_required" label={t('createEvent-approval')} />
			)} */}
			<FlexContainer flexDirection="column" width="100%">
				<InputLabel htmlFor="images" label={t('generic-event-image')} />
				<ImgWithHover inputId="images" />
			</FlexContainer>
			<EventSchedule isEventCreation />
			<Form.InputDateAndTime
				id="sale_date"
				name="sale_date"
				label={t(`createEvent-${eventMode == EVENT_MODE_NORMAL ? 'tickets' : 'reg'}-saleDate`)}
			/>
			<Form.TimeZone />
		</>
	);
};
