import * as Styled from './LotteryEntries.styles';

import { debounce, sendGAEvent } from 'utils/utils';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { EntriesTable } from './components/EntriesTable/EntriesTable';
import { Icon } from 'features/common/components/Icon';
import { IdParamsType } from 'utils/types';
import { MainHeader } from 'features/common/components/MainHeader';
import { MainLayout } from 'layouts/MainLayout';
import { ROUTES } from 'features/routes';
import { RunLotteryModal } from './components/RunLotteryModal/RunLotteryModal';
import { Search } from 'features/common/components/Search';
import { getData } from 'services/api';
import { handleDownload } from 'utils/handleLink';
import { useEventLotteryEntries } from './hooks/useEventLotteryEntries';
import { useTranslation } from 'react-i18next';

export const LotteryEntries = () => {
	const { t } = useTranslation();
	const { push } = useHistory();
	const { id } = useParams<IdParamsType>();
	const { counts, setSearchText, entriesQueryObject } = useEventLotteryEntries();

	const [isLotteryModalOpen, setIsLotteryModalOpen] = useState(false);
	const [runButtonDisabled, setRunButtonDisabled] = useState(false);

	const searchDebounce = debounce(setSearchText);

	useEffect(() => {
		if (counts) {
			const disabled = counts.pending === 0 && counts.winner === 0 && counts.not_winner === 0;
			setRunButtonDisabled(disabled);
		}
	}, [counts]);

	const onClickDownload = async () => {
		const response = await getData(`/events/${id}/lottery/entries/csv`);
		handleDownload(response, 'entries.csv');
		sendGAEvent({ category: 'Custom Fields', action: 'Custom Fields Responses download' });
	};

	return (
		<MainLayout>
			<MainHeader lessPadding gap="12px">
				<Icon cursor="pointer" iconName="arrowLeft" onClick={() => push(generatePath(ROUTES.DASHBOARD_PATH, { id }))} />
				<Styled.Title element="h1">Lottery Entries</Styled.Title>
			</MainHeader>

			<Styled.Container>
				<Styled.CustomFlexContainer>
					<Search
						width="300px"
						onChange={(e) => searchDebounce(e.target.value)}
						placeholder={t('search-email-or-name')}
					/>
					<Styled.Buttons>
						<Styled.CsvButton onClick={() => setIsLotteryModalOpen(true)} disabled={runButtonDisabled}>
							Run Lottery
						</Styled.CsvButton>
						<Styled.CsvButton onClick={onClickDownload}>{t('customFields-responses-download')}</Styled.CsvButton>
					</Styled.Buttons>
				</Styled.CustomFlexContainer>

				<EntriesTable entriesQueryObject={entriesQueryObject} />
			</Styled.Container>

			<RunLotteryModal
				showModal={isLotteryModalOpen}
				closeModal={() => setIsLotteryModalOpen(false)}
				refetch={entriesQueryObject.refetch}
				counts={counts}
			/>
		</MainLayout>
	);
};
