import { LotteryEntryStatus } from 'features/LotteryEntries/types';

export const getStatus = (status: LotteryEntryStatus) => {
	switch (status) {
		case 'winner':
			return 'Winner (not claimed)';
		case 'not_winner':
			return 'Not Winner';
		case 'pending':
			return 'Pending';
		case 'claimed':
			return 'Claimed';
		default:
			return status;
	}
};
