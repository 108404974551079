import { ButtonContainer, FormContainer } from './PunchupEditEventForm.styles';
import { IdParamsType, StateProps } from 'utils/types';
import { getFormDetails, handleInitialValues } from './utils';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { DateTime } from 'luxon';
import { EditEventFormProps } from './types';
import { Form } from 'features/common/components/Form';
import { MainLayout } from 'layouts/MainLayout';
import { PunchupButton } from 'features/common/components/Form/PunchupButton/PunchupButton';
import { PunchupDateTimePicker } from 'features/common/components/Form/PunchupDateTimePicker/PunchupDateTimePicker';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const PunchupEditEventForm = ({ event, editEvent }: EditEventFormProps) => {
	const { t } = useTranslation();
	const { goBack } = useHistory();
	const { id }: IdParamsType = useParams();
	const { state: { isFromRequiredFields } = { isFromRequiredFields: false } }: StateProps = useLocation();

	const formMethods = useForm({
		defaultValues: handleInitialValues(event),
		resolver: getFormDetails(event.mode),
	});

	const startDate = formMethods.watch('dates.0.start_date');
	const doorTime = formMethods.watch('door_time');

	const { errors } = formMethods.formState;

	const onEditEvent = (values: any) => {
		editEvent({ values, id });
	};

	return (
		<MainLayout>
			<Form formMethods={formMethods} onSubmit={onEditEvent} width="100%">
				<FormContainer>
					<Form.PunchupInput
						id="name"
						name="name"
						label={t('editEvent-eventName')}
						isFromRequiredFields={isFromRequiredFields}
					/>

					<Form.PunchupInput id="venue" name="venue" label={t('editEvent-venue')} disabled />
					<Form.PunchupInput id="location" name="location" label={t('editEvent-eventLocation')} disabled />

					<Form.PunchupTextArea id="description" name="description" label={t('editEvent-event-description')} />

					<PunchupDateTimePicker
						id="startDate"
						name="dates[0].start_date"
						label={t('Start Date')}
						required
						minDate={new Date()}
					/>
					<PunchupDateTimePicker
						id="endDate"
						name="dates[0].end_date"
						label={t('End Date')}
						required
						minDate={startDate}
					/>
					<PunchupDateTimePicker
						id="doorTime"
						name="door_time"
						label={t('Open Doors')}
						required
						maxDate={startDate}
						minTime={new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 0, 0, 0)}
						maxTime={
							doorTime && DateTime.fromJSDate(startDate).hasSame(DateTime.fromJSDate(new Date(doorTime)), 'day')
								? DateTime.fromJSDate(startDate).toJSDate()
								: new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 23, 59, 59)
						}
					/>

					<Form.PunchupTimezone />
				</FormContainer>

				<ButtonContainer>
					<PunchupButton type="button" variant="secondary" onClick={goBack} text="Cancel" />
					<PunchupButton type="submit" variant="primary" text="Save" disabled={Object.keys(errors).length > 0} />
				</ButtonContainer>
			</Form>
		</MainLayout>
	);
};
