import { DateSchema, array, date, object, string } from 'yup';
import { EVENT_MODE_NORMAL, EVENT_MODE_PUNCHUP } from 'utils/constants';

import { AllDefaultValues } from './types';
import { EventMode } from 'utils/types';
import i18n from 'locals/i18n';
import { v4 as uuid } from 'uuid';
import { yupResolver } from '@hookform/resolvers/yup';

const yesterday = new Date();
yesterday.setDate(yesterday.getDate() - 1);
yesterday.setHours(0, 0, 0, 0);
yesterday.setMinutes(0);

const startDate = new Date();
startDate.setDate(startDate.getDate() + 1);
startDate.setHours(0, 0, 0, 0);
startDate.setMinutes(0);

const endDate = new Date(startDate);
endDate.setHours(endDate.getHours() + 2);

export const defaultValues: AllDefaultValues = {
	mode: EVENT_MODE_NORMAL,
	name: '',
	location: '',
	city: '',
	state: '',
	description: '',
	images: [],
	new_images: [],
	dates: [
		{
			start_date: startDate,
			end_date: endDate,
			id: uuid(),
		},
	],
	sale_date: yesterday,
	timezone: '',
	gate_time: '',
	venue: '',
	support: '',
	postal_code: '',
	door_time: null,
	ticketing_mode: 'static',
	quantity: '',
	approval_required: false,
};

export const getFormDetails = (eventMode: EventMode) => {
	const sharedDetails = {
		name: string().required(i18n.t('editEvent-requiredName')),
		location: string().required(i18n.t('editEvent-requiredLocation')),
		timezone: string().required(i18n.t('editEvent-requiredTimezone')),
		dates: array(
			object({
				start_date: date().required(),
				end_date: date()
					.required()
					.when('start_date', (start_date: Date, schema: DateSchema) =>
						schema.min(start_date, i18n.t('error-endDate'))
					),
			})
		),
	};

	const normalSchema = object().shape({
		...sharedDetails,
		postal_code: string().required(i18n.t('editEvent-requiredZipcode')),
	});

	const registrationSchema = object().shape({
		...sharedDetails,
		quantity: string().required(i18n.t('editEvent-requiredQuantity')),
	});

	const punchupSchema = object().shape({
		name: string().min(3, i18n.t('editEvent-requiredName')).required(i18n.t('editEvent-requiredName')),
		timezone: string().required(i18n.t('editEvent-requiredTimezone')),
		door_time: date()
			.optional()
			.when('start_date', (start_date: Date, schema: DateSchema) => {
				if (start_date) {
					return schema.max(start_date, i18n.t('error-doorTime-before-startDate'));
				}
				return schema;
			}),
		dates: array(
			object({
				start_date: date().required(i18n.t('editEvent-requiredStartDate')),
				end_date: date()
					.required(i18n.t('editEvent-requiredEndDate'))
					.when('start_date', (start_date: Date, schema: DateSchema) =>
						schema.min(start_date, i18n.t('error-endDate'))
					),
			})
		),
	});

	if (eventMode === EVENT_MODE_NORMAL) {
		return yupResolver(normalSchema);
	} else if (eventMode === EVENT_MODE_PUNCHUP) {
		return yupResolver(punchupSchema);
	} else {
		return yupResolver(registrationSchema);
	}
};
