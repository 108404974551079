import {
	ContainerActionButtons,
	ContainerBody,
	ModalButton,
	SelectTypeContainer,
	Text,
} from './RunLotteryModal.styles';
import { FC, useEffect, useState } from 'react';
import { Input, Label } from 'features/common/components/Form/Form.styles';
import { QUATERNARY, TERTIARY } from 'features/common/components/Button/constants';

import { CheckboxButton } from 'features/common/components/CheckboxButton/CheckBoxButton.styles';
import FlexContainer from 'features/common/components/FlexContainer/FlexContainer';
import { IdParamsType } from 'utils/types';
import { LotteryEntryStatus } from '../../types';
import { Modal } from 'features/common/components/Modal/Modal';
import { RunLotteryModalProps } from './types';
import { getStatus } from './utils';
import { post } from 'services/api';
import { toastError } from 'hooks/useToastNotification/useToastNotification';
import { toastSuccess } from 'hooks/useToastNotification/useToastNotification';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const RunLotteryModal: FC<RunLotteryModalProps> = ({ showModal, closeModal, counts, refetch }) => {
	const { t } = useTranslation();
	const { id: eventId }: IdParamsType = useParams();

	const [typesSelected, setTypesSelected] = useState<LotteryEntryStatus[]>(['pending']);
	const [numberOfWinners, setNumberOfWinners] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [maxWinners, setMaxWinners] = useState(0);

	useEffect(() => {
		if (counts && typesSelected.length > 0) {
			const max = typesSelected.reduce((acc, type) => acc + counts[type], 0);
			setMaxWinners(max);
		} else {
			setMaxWinners(0);
		}
	}, [counts, typesSelected]);

	const handleRunLottery = async () => {
		setIsLoading(true);

		try {
			await post(`/events/${eventId}/lottery/run`, {
				number_of_winners: Number(numberOfWinners),
				status: typesSelected,
			});

			refetch();
			toastSuccess('Lottery run successfully');
		} catch (error) {
			toastError('Error running lottery');
		} finally {
			closeModal();
			setIsLoading(false);
		}
	};

	const handleChangeNumberOfWinners = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = Number(e.target.value);
		if (!value) {
			setNumberOfWinners('');
		} else if (value && value <= maxWinners) {
			setNumberOfWinners(value.toString());
		}
	};

	const handleSelectType = (type: LotteryEntryStatus) => {
		if (typesSelected.includes(type)) {
			setTypesSelected(typesSelected.filter((t) => t !== type));
		} else {
			setTypesSelected([...typesSelected, type]);
		}
	};

	if (!showModal) return null;

	return (
		<Modal onClose={closeModal}>
			<Modal.Header>Run Lottery</Modal.Header>
			<Modal.Body>
				<ContainerBody>
					<Text element="p">This action cannot be undone.</Text>

					<FlexContainer flexDirection="column" gap="10px" width="100%">
						<Label variant="secondary">Select status</Label>
						<FlexContainer gap="18px" width="100%">
							{['pending', 'not_winner', 'winner'].map((type) => (
								<SelectTypeContainer key={type} onClick={() => handleSelectType(type as LotteryEntryStatus)}>
									<CheckboxButton type="checkbox" checked={typesSelected.includes(type as LotteryEntryStatus)} />
									<Label variant="secondary">{getStatus(type as LotteryEntryStatus)}</Label>
								</SelectTypeContainer>
							))}
						</FlexContainer>
					</FlexContainer>

					<FlexContainer flexDirection="column" gap="10px" width="100%">
						<Label variant="secondary">Number of winners (max {maxWinners})</Label>
						<Input
							type="number"
							placeholder="Enter number of winners"
							style={{ color: 'black' }}
							value={numberOfWinners}
							onChange={handleChangeNumberOfWinners}
						/>
					</FlexContainer>
				</ContainerBody>
			</Modal.Body>
			<Modal.ActionButtons>
				<ContainerActionButtons>
					<ModalButton
						category={QUATERNARY}
						onClick={closeModal}
						text={t('button-cancel')}
						type="button"
						disabled={isLoading}
					/>
					<ModalButton
						category={TERTIARY}
						onClick={handleRunLottery}
						text={t('button-run-lottery')}
						type="button"
						disabled={isLoading}
					/>
				</ContainerActionButtons>
			</Modal.ActionButtons>
		</Modal>
	);
};
