import { EntriesData, LotteryEntriesCounts } from '../types';
import { useRef, useState } from 'react';

import { IdParamsType } from 'utils/types';
import { getResponse } from 'services/api';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

export const useEventLotteryEntries = () => {
	const { id } = useParams<IdParamsType>();

	const headersRef = useRef(0);

	const [searchText, setSearchText] = useState('');
	const [pageParam, setPageParam] = useState(1);
	const [resultsPerPage, setResultsPerPage] = useState(10);
	const [totalEntries, setTotalEntries] = useState(0);
	const [counts, setCounts] = useState<LotteryEntriesCounts>();

	const entriesQueryObject = useInfiniteQuery({
		queryKey: ['lottery-entries', id, searchText],
		queryFn: async ({ pageParam = 1 }): Promise<EntriesData[]> => {
			const searchParams = new URLSearchParams();
			searchParams.set('page', pageParam.toString());
			searchParams.set('per_page', resultsPerPage.toString());
			searchText && searchParams.set('search', searchText);

			const { data, headers } = await getResponse(`/events/${id}/lottery/entries?${searchParams.toString()}`);

			setCounts(data.counts);

			headersRef.current = Number(headers['x-total-count']);
			setTotalEntries(headersRef.current);

			return data.entries;
		},
		getNextPageParam: (_, pages) => {
			const maxPages = Math.round(headersRef.current / resultsPerPage);
			const nextPage = pages.length;
			return nextPage < maxPages ? nextPage : undefined;
		},
	});

	return {
		searchText,
		setSearchText,
		pageParam,
		setPageParam,
		resultsPerPage,
		setResultsPerPage,
		entriesQueryObject,
		totalEntries,
		counts,
		refetch: entriesQueryObject.refetch,
	};
};
